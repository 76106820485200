import React from 'react'
import Fade from 'react-reveal/Fade';

import './Meth.css'

const Meth = () => {
    return (
        <div className='meth submenu'>

                <div className="meth-content">
                    <Fade top>
                    <h1>METHAMPHETAMINE</h1>
                    <h2>SUBSTANCE ABUSE ADDICTION</h2>
                    </Fade>
            </div>
        </div>
    )
}

export default Meth
