import React from 'react'
import Fade from 'react-reveal/Fade';

import './Adhd.css'



const Adhd = () => {
    return (
        <div className='adhd submenu'>
                <div className="adhd-content">
                    <Fade top>
                    <h1>ATTENTION DEFICIT/HYPERACTIVITY DISORDER</h1>
                    <h2>MENTAL HEALTH TREATMENT</h2>
                    </Fade>
            </div>
        </div>
    )
}

export default Adhd
