import React from 'react'
import Fade from 'react-reveal/Fade';

import './PTSD.css'


const PTSD = () => {
    return (
        <div className='ptsd submenu'>

                <div className="ptsd-content">
                    <Fade top>
                    <h1>POST-TRAUMATIC STRESS DISORDER</h1>
                    <h2>MENTAL HEALTH TREATMENT</h2>
                    </Fade>
            </div>
        </div>
    )
}

export default PTSD
